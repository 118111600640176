import {ModaleDiffererLivraison} from '@/components/MenuLivraison/ModaleDiffererLivraison';
import {CalendarIcon} from '@chakra-ui/icons';
import NiceModal from '@ebay/nice-modal-react';
import {ErrorButton} from './ErrorButton';
import {useTranslation} from 'react-i18next';
import {LigneDePanierCommands} from 'ff-common/src/hooks/ligneDePanier/use-ligne-de-panier-commands';
import {State} from '@synako/halitrek';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';

export const DiffererErrorButton: React.FC<{
  // probleme: LigneProblem,
  ligneDePanier: State<LigneDePanierDesc>,
  commands: LigneDePanierCommands,
  onClose?: () => void
  submittingOrRefreshing?: boolean,
}> = ({
  commands,
  ligneDePanier,
  onClose,
  submittingOrRefreshing,
}) => {
  const {t} = useTranslation();
  const {somethingSubmitting, differer} = commands;
  const onClick = () => {
    NiceModal.show(ModaleDiffererLivraison, {
      validateAction:async (date: string)=>{
        differer.submit({
          motif: 'rupture',
          date_de_livraison: date,
        });
      },
      dispoNav: ligneDePanier.follow('disponibilite_article'),
    });
    onClose?.();
  };

  return (
    <>
      <ErrorButton
        display={{base: 'none', md: 'block'}}
        leftIcon={<CalendarIcon />}
        px="2" 
        flex="1.6" 
        maxW="220px"
        libelle={t('ligne_produit.indisponible.differer')}
        refreshing={somethingSubmitting || submittingOrRefreshing}
        onClick={onClick}
      />
      <ErrorButton
        flex='1'
        display={{base: 'block', md: 'none'}}
        leftIcon={<CalendarIcon />}
        w="50%"
        minW="150px"
        maxW="220px"
        libelle={t('ligne_produit.indisponible.differer')}
        refreshing={somethingSubmitting || submittingOrRefreshing}
        onClick={onClick}
      />
    </>
  );
};
