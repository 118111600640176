import {useCadencier, useListes} from '@/haligator-factory';
import {HaliRenderOne, useHalCollection} from '@synako/haligator';
import {Box, HStack, Text, VStack} from '@chakra-ui/layout';
import {Divider, IconButton, Image, Input, Tooltip} from '@chakra-ui/react';
import {AddIcon, ArrowForwardIcon, CheckIcon, CloseIcon, DeleteIcon, EditIcon, ViewIcon} from '@chakra-ui/icons';
import {Link, useNavigate} from 'react-router-dom';
import {routes} from 'ff-common';
import {useCallback, useState} from 'react';
import {ListeDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import NiceModal from '@ebay/nice-modal-react';
import {useTranslation} from 'react-i18next';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {ModalCreateListe} from './components/ModalCreateListe';
import {BlueButton} from '@/components/Button/BlueButton';
import {Chargement} from '@/components/Chargement/Chargement';
import {ModaleAlerteConfirmation} from '../panier/PanierFound/ModaleAlerteConfirmation';
import {useCapture} from '@/posthog/PosthogSetup';

export const MesListes: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const capture = useCapture();

  const {items, refreshing, loading} = useHalCollection(useListes());
  
  const [editeListeId, setEditeListeId] = useState<string | null>(null);
  const [nouveauNom, setNouveauNom] = useState('');

  const handleClickCadencier = () => {
    capture({name: 'cadencier:go', props: {depuis: 'liste'}});
    navigate(buildAssistanceRoute(routes.client.cadencier));
  };

  const handleDeleteList = (id: string, listeState: State<ListeDesc>) => {
    NiceModal.show(ModaleAlerteConfirmation, {
      validate_texte:t('liste.supprimer_liste_action'),
      body: t('liste.supprimer_liste_confirm'),
      validateAction: () => {
        deleteListAction(id, listeState);
      }
    });
  };

  const handleRenommerListe = (id: string, listeState: State<ListeDesc>) => {
    renameListAction(id, listeState, nouveauNom);
    setEditeListeId(null);
  };

  const handleEditClick = (id: string, currentName: string) => {
    setEditeListeId(id);
    setNouveauNom(currentName);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNouveauNom(e.target.value);
  };

  const deleteListAction = useCallback(async (listeId: string, listeState: State<ListeDesc>)=>{
    try{
      await listeState.submitCommand('c:supprimer-une-liste', {liste_id:listeId});
      capture({
        name: 'liste:supprimer',
        props: {
          liste: listeState.data.id
        }
      });
      textToast(t('liste.liste_supprimee', {nom: listeState.data.nom_liste}));
    }
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      onError(error);
    }
  }
  ,[capture, textToast, t, onError]);

  const renameListAction = useCallback(async (listeId: string, listeState: State<ListeDesc>, nouveauNomListe: string)=>{
    try{
      await listeState.submitCommand('c:modifier-nom-d-une-liste', {liste_id:listeId, nouveau_nom_liste:nouveauNomListe});
      textToast(t('liste.liste_renommee'));
    }
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    catch (error: any) {
      onError(error);
    }
  }
  ,[textToast, t, onError]);

  return(    
    <Box backgroundColor="white"
      p={4}
      borderRadius="md"
      boxShadow="md"
      maxW='1100px'
      m={{base:'none', md:'30px auto 30px'}}>
      <VStack align="stretch"
        spacing={4}
        p='20px'>
        <HStack justifyContent='space-between'>
          <Text fontSize="xl"
            fontWeight="bold">{t('liste.listes')}</Text>
          <BlueButton texte={t('liste.creer_liste')}
            onClick={() => NiceModal.show(ModalCreateListe)}
            leftIcon={<AddIcon />}
            mb='10px'/>
        </HStack>

        <HStack bg='blue.main_light'
          borderRadius='8px'
          padding='20px'
          p='20px 20px 0px 40px'
          justifyContent='space-between'
          cursor='pointer' 
          _hover={{opacity:0.8}}
          onClick={handleClickCadencier}>
          <VStack justify="space-between"
            alignItems='start'>
            <Text fontWeight='700'
              color='blue.main'
              fontSize={{md:'lg', base:'sm'}}
              mt='20px'
              mb='10px'>{t('cadencier.mon_cadencier')} <ArrowForwardIcon/></Text>
            <HaliRenderOne nav={useCadencier()}
              render={({data})=>{
                return <Text color='blue.main'
                  fontSize={{md: 'md', base: 'sm'}}
                  mb='20px'>{t('global.produit', {count: data.nombre_articles})}</Text>;
              }}/>
          </VStack>
          
          {/* Uniquement en desktop */}
          <VStack display={{base:'none', md:'flex'}}
            mr='40px'
            height='100%'
            justifyContent='end'
            alignItems='end'>
            <Image src={`${import.meta.env.BASE_URL}/categories/104.webp`}
              width='245px'/>
          </VStack>

        </HStack>
        {(loading || refreshing) ? (
          <Chargement texte={t('liste.chargement_listes')}/>
        ) : (
          items?.map((liste) => {
            if (!liste.data.code_liste) {
              return null;
            }
            const itemsList = liste.followAllOrThrow('selection');
            const isEditing = editeListeId === liste.data.id;
            return (
              <HStack key={liste.data.id}
                justify="space-between"
                _hover={{bg:'gray.bg'}}
                border='1px solid'
                borderColor='gray.light'
                p='20px 20px 20px 40px'>
                <HStack spacing={2}>
                  {isEditing ? (
                    <>
                      <form onSubmit={e => {e.preventDefault(); handleRenommerListe(liste.data.id, liste);}}>
                        <Input value={nouveauNom} 
                          onChange={handleInputChange} 
                          onSubmit={e => {e.preventDefault(); handleRenommerListe(liste.data.id, liste);}}
                          autoFocus
                          bg='white'/>
                      </form>
                      <IconButton aria-label={t('liste.aria_label_enregistrer')}
                        icon={<CheckIcon />}
                        onClick={() => {handleRenommerListe(liste.data.id, liste);}}
                        size="sm"
                        bg='blue.main'
                        _hover={{bg: 'blue.300'}}
                        color='white' />
                      <IconButton aria-label="Annuler"
                        icon={<CloseIcon />}
                        onClick={() => {setEditeListeId(null);}}
                        size="sm" />
                    </>
                  ) : (
                    <>
                      <Link
                        style={{flex: 1, display: 'flex', justifyContent: 'end', alignItems:'center'}}
                        to={buildAssistanceRoute(routes.client.listes, {code_liste:liste.data.code_liste ?? ''})}
                      >
                        <Text fontSize={{md:'lg', base: 'sm'}}
                          fontWeight='700'>{liste.data.nom_liste}</Text>
                      </Link>
                      <Tooltip label={t('liste.aria_label_modifier')}
                        hasArrow>
                        <IconButton aria-label={t('liste.editer_nom_liste')}
                          icon={<EditIcon />}
                          onClick={() => {handleEditClick(liste.data.id, liste.data.nom_liste);}}
                          size="sm"
                          variant="ghost" />
                      </Tooltip>
                    </>
                  )}
                  {!isEditing &&
                <Link
                  style={{flex: 1, display: 'flex', justifyContent: 'end', alignItems:'center'}}
                  to={buildAssistanceRoute(routes.client.listes, {code_liste:liste.data.code_liste ?? ''})}
                >
                  <Box p='2px 5px 2px 5px'
                    bg='gray.light'
                    borderRadius='5px'>
                    {/* Mobile */}
                    <Text display={{base:'block', md:'none'}}
                      fontSize='sm'>
                      {itemsList.length}
                    </Text>
                    {/* Desktop */}
                    <Text display={{base:'none', md:'block'}}
                      fontSize='sm'>
                      {t('global.produit', {count: itemsList.length})}
                    </Text>
                  </Box>
                </Link>
                  }
                </HStack>
                <Link
                  style={{flex: 1, display: 'flex', justifyContent: 'end'}}
                  to={buildAssistanceRoute(routes.client.listes, {code_liste:liste.data.code_liste ?? ''})}
                >
                  <HStack justifyContent='end'>
                    <HStack mr='20px'>
                      <Text>
                        <ViewIcon mr='5px'/>
                        <Text as='span'
                          display={{base:'none', md:'inline'}}>{t('global.consulter')}</Text>
                      </Text>
                    </HStack>
                  </HStack>
                </Link>
                <Divider orientation='vertical'
                  height='30px'
                  borderColor='gray.dark'/>
                <HStack ml='15px'
                  onClick={() => {handleDeleteList(liste.data.id, liste);}}
                  cursor='pointer'>
                  <DeleteIcon/>
                </HStack>
              </HStack>
            );
          })
        )}
      </VStack>
    </Box>
  );
};
