import {Box, HStack, Spacer, VStack} from '@chakra-ui/react';
import {LignePanierRemplacementPicto} from '@/domains/ligneProduit/LignePanierRemplacementPicto';
import {ArticleBlock} from '../ArticleBlock';
import {PrixBlock} from '../PrixBlock';
import {useTranslation} from 'react-i18next';
import {DeleteBlock} from '../DeleteBlock';
import React, {useContext} from 'react';
import {useHalState} from '@synako/haligator';
import {PoidsVariableTooltip} from './PoidsVariableTooltip';
import {useBuyingBlocks} from '@/hooks/panier/useBuyingBlocks';
import {LigneProduitLayout} from '@/domains/liste/components/LigneProduitLayout';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {LigneDePanierDesc} from 'api-types/ffconnect-hal';
import {State} from '@synako/halitrek';
import {LigneProblem} from 'api-types/ffconnect';
import {Warning} from './Warning';
import {ProduitDejaCommande} from '@/domains/ligneProduit/ProduitDejaCommande';
import isEqual from 'react-fast-compare';
import {useSimpleTextToast} from '@/hooks/useSimpleTextToast';
import {useDefaultOnError} from '@/hooks/useDefaultOnError';
import {useLigneDePanierCommands} from 'ff-common';
import {ModaleRupture} from '@/domains/cadencier/components/ModaleRupture';
import {useCapture} from '@/posthog/PosthogSetup';

export const ProduitPanierAffiche: React.FC<{
  ligneDePanier: State<LigneDePanierDesc>,
  idError: string,
  probleme: LigneProblem | undefined,
}> = React.memo (({
  ligneDePanier,
  idError,
  probleme,
}) => {
  const {t} = useTranslation();
  const isMobile = useContext(BreakPointContext)?.isMobile;
  const capture = useCapture();

  const remplacement_de = ligneDePanier.data.remplacement_de;
  // Gestion à un seul endroit des commandes et donc du somethingSubmitting
  const textToast = useSimpleTextToast();
  const onError = useDefaultOnError(textToast);
  const commands = useLigneDePanierCommands(ligneDePanier, textToast, onError, ModaleRupture);
  const {
    somethingSubmitting,
    supprimer,
  } = commands;

  const item = useHalState(ligneDePanier.follow(('item')));
  const prixType = item.data?.tolede.prix_par_livraison?.[0]?.type;

  const articleBlock = <ArticleBlock
    code_article={ligneDePanier.data.code_article}
    article={ligneDePanier.follow('article')}
    dispo={ligneDePanier.follow('disponibilite_article')}
    libelleFallback={ligneDePanier.data.libelle}
    typePrix={prixType}
  />;
  const prixBlock = <PrixBlock
    refreshing={somethingSubmitting}
    ligneDePanier={ligneDePanier}
  />;
  
  const article = useHalState(ligneDePanier.follow('article'));
  const {uniteQuantiteBlock, buyBlock} = useBuyingBlocks(textToast, 'panier', item.state, ligneDePanier);
  const selectBlock = <Box 
    mt='15px'
    ml={{base: 0, md: 'auto'}}
    minW='fit-content'
  >
    {uniteQuantiteBlock({minW:'245px', w:'245px'})}
    {article.data?.tolede.a_peser && <PoidsVariableTooltip/>}
  </Box>;
  
  const warning = probleme && 
    <Warning
      idError={idError}
      ligneDePanier={ligneDePanier}
      probleme={probleme}
      commands={commands}
    />;

  const produitDejaCommandeBlock = item.state && <ProduitDejaCommande itemState={item.state} />;

  return <LigneProduitLayout
    flexDirection='column'
    // opacity={refreshing ? 0.5 : 1}
  >

    <DeleteBlock onClick={ () => {
      capture({
        name: 'panier:enlever',
        props: {
          depuis: 'panier',
          code: ligneDePanier.data.code_article,
        }
      });
      supprimer.submit({});
    }}/>

    {remplacement_de && (
      <LignePanierRemplacementPicto remplacement_de={remplacement_de}
        t={t} />
    )}

    {!isMobile ?
    // Desktop
      <HStack 
        width='100%' 
        alignItems='start' 
        justify='end' 
        flexWrap='wrap' 
        gap='0'
        pt="0px" 
        flex='1' 
      >
        {articleBlock}
        <Spacer/>
        {prixBlock}
        <HStack justifyContent='end'
          alignItems='start'
          justifySelf='end'
          w="100%">
          {selectBlock}
          {buyBlock({somethingSubmitting})}
        </HStack>
        {produitDejaCommandeBlock}
        {warning}
      </HStack>

    // Mobile
      : <VStack
        display={{base: 'block', md: 'none'}}
        width='100%' 
        alignItems='start' 
        justify='end'
      >
        {articleBlock}
        <Spacer />
        <HStack justify='space-between'>
          {selectBlock}
          {prixBlock}
        </HStack>
        {buyBlock({somethingSubmitting})}
        {produitDejaCommandeBlock}
        {warning}
      </VStack>} 

  </LigneProduitLayout>;
}, function propsAreEqual(prevProps, nextProps) {
  const same = isEqual(prevProps.ligneDePanier, nextProps.ligneDePanier)
    && isEqual(prevProps.probleme, nextProps.probleme);
  return same;
});
