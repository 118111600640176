import {Box, Stack, VStack} from '@chakra-ui/layout';
import {CadencierHeader} from './components/CadencierHeader';
import {HaliRenderOne, useHalState, useHalStates} from '@synako/haligator';
import {useCadencier} from '@/haligator-factory';
import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  routes,
  useLivraisonsPossiblesContext
} from 'ff-common';

import {ArrowForwardIcon} from '@chakra-ui/icons';
import {useTranslation} from 'react-i18next';
import {useIsAssistanceMode} from '@/hooks/auth/useIsAssistanceMode';
import {usePanier} from 'ff-common';
import {BlueButton} from '@/components/Button/BlueButton';
import {Skeleton} from '@chakra-ui/react';
import {CadencierSection} from './controllers/CadencierSection';
import {CadencierTitreNombreDeProduits} from './components/CadencierTitreNombreDeProduits';
import {TemperatureDeLivraison, TriSelection} from 'api-types/ffconnect';
import React from 'react';
import {BreakPointContext} from '@/chakra/BreakpointProvider';
import {FrozenToggle} from './components/FrozenToggle';
import {useCapture} from '@/posthog/PosthogSetup';

export const MonCadencier: React.FC = () => {
  const cadencierNav = useCadencier();
  const [editMode, setEditMode] = useState<boolean>(false);
  //Lors d'une tournée négative ou positive, affiche ou non les articles livrables
  const [filtreTemperature, setFiltreTemperature] = useState<TemperatureDeLivraison>('les_deux');

  const {livraisonSelectionnee} = useLivraisonsPossiblesContext();
  const tourneeHook = useHalState(livraisonSelectionnee.follow('tournee'));
  const temperature_de_livraison = tourneeHook.data?.tolede.temperature_de_livraison ?? 'les_deux';

  const temperature_de_livraison_selection = filtreTemperature !== 'les_deux';

  useEffect(() => {
    //Valeur par défaut
    setFiltreTemperature(temperature_de_livraison);
  }, [temperature_de_livraison]);

  return (
    <HaliRenderOne nav={cadencierNav} 
      skeleton={()=><Skeleton width='100%'
        height='10'
        m='5'/>}
      render={({data: cadencier}) => {
        const nombre_articles_filtres = cadencier.tri_selection.reduce((acc, {articles}) => {
          if (filtreTemperature === 'les_deux') return acc + articles.length; 
          return acc + articles.filter((a)=>a.temperature_livraison===filtreTemperature).length;
        }, 0); 
        return (
          <Box bg='gray_bg'
            py={{base:'0', lg:'10'}}
            px={{base:'0', md: '2', xl: '20'}}>
            <VStack bg={{lg:'white'}}
              p={{base:'0', md:'4'}}
              gap={{base:'0',lg:'10'}}
              alignItems='start'
              position='relative'>
              <Stack gap='0'
                flexDirection='column'
                justify='start'
                width='100%'
                alignItems='baseline'
                bg='white'
                display={{base:'block', md:'none'}}
                position='sticky'
                top='114px'
                zIndex='sticky'>
                <CadencierTitreNombreDeProduits nombreDeProduits={nombre_articles_filtres} />
              </Stack>
              <Box id='cadencier_header_container'
                width="100%"
                zIndex='dropdown'
                position={{md:'sticky'}}
                top={{md:'120px'}}
                bg='white'>
                <CadencierHeader
                  nombreDeProduits={
                    nombre_articles_filtres
                  }
                  editMode={editMode}
                  setEditMode={setEditMode}
                  categories={cadencier.tri_selection}
                />
                {
                  temperature_de_livraison!=='les_deux' && <FrozenToggle 
                    temperature_de_livraison={temperature_de_livraison} 
                    isChecked={temperature_de_livraison_selection}
                    onChange={()=>{
                      setFiltreTemperature(temperature_de_livraison_selection ? 'les_deux' : temperature_de_livraison);
                    }}/>
                }
              </Box>
              <Box id='cadencier_body_container'
                width="100%">
                <CadencierBody
                  tri_selection={cadencier.tri_selection}
                  editMode={editMode}
                  filtreTemperature={filtreTemperature}
                />
              </Box>
              <ActiveGoToPanier />
            </VStack>
          </Box>
        );
      }} />
  );
};

const ActiveGoToPanier = () => {
  const {t} = useTranslation('common');
  const capture = useCapture();
  const {buildAssistanceRoute} = useIsAssistanceMode();
  const {state: panier} = useHalState(usePanier());
  return <Box m='5'
    ml='auto'>
    {panier && 
      <Link 
        onClick={()=>{capture({name: 'panier:go', props: {depuis: 'cadencier'}});}}
        to={buildAssistanceRoute(routes.client.panier, {date_panier_actif: panier.data.livraison_tolede.date_de_livraison})}>
        <BlueButton texte={t('cadencier.cta_panier')}
          rightIcon={<ArrowForwardIcon/>}
          p='7'
          m='2'/>
      </Link>
    }
  </Box>;
};

const CadencierBody:React.FC<{
  tri_selection:TriSelection,
  editMode:boolean,
  filtreTemperature: TemperatureDeLivraison
}> = ({tri_selection, editMode, filtreTemperature}) => {
  const lignesHook = useHalStates(usePanier().followAll('lignes_de_panier'));

  const view = useContext(BreakPointContext);

  return tri_selection.map(({categorie_id, libelle_web, articles}) => (
    <CadencierSection
      id={categorie_id}
      key={categorie_id}
      title={libelle_web}
      articles={filtreTemperature==='les_deux' 
        ? articles 
        : articles.filter(({temperature_livraison})=>temperature_livraison === filtreTemperature)}
      editMode={editMode}
      lignesDePanier={lignesHook.states ?? []}
      isMobile={!!view?.isMobile}
    />
  ));
};
