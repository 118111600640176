import {HookUseTypedFilters} from '@synako/typed-path';
import {ArrowUpDownIcon, ChevronDownIcon} from '@chakra-ui/icons';
import {Box, BoxProps, Button, Checkbox, Circle,HStack, Popover, PopoverBody, PopoverContent, PopoverTrigger, Select, Tag, TagCloseButton, TagLabel, Text, Tooltip} from '@chakra-ui/react';
import {Results} from '@orama/orama';
import {HitItemCatalogue, TemperatureLivraison} from 'api-types/ffconnect';
import {FilterIcon} from '@/components/svg/FilterIcon';
import {CatalogFilterModale} from './CatalogFilterModale';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {Flex, FlexProps, Wrap, WrapItem} from '@chakra-ui/layout';
import {FiltresItemCatalogue} from 'ff-common/src/hooks/search/use-search';
import {useMemo, useState} from 'react';
import {useClient} from '@/haligator-factory';
import {HaliRenderOne} from '@synako/haligator';
import {useCapture} from '@/posthog/PosthogSetup';

export const CatalogFilter: React.FC<{
  modaleRemplacement?: boolean;
  itemResults: Results<HitItemCatalogue> | undefined;
  direction?: FlexProps['direction'];
  filtersHook: HookUseTypedFilters<FiltresItemCatalogue>,
  props?:BoxProps,
}> = ({
  modaleRemplacement,
  itemResults,
  direction,
  filtersHook,
  ...props
}) => {
  const clientNav = useClient();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const paysOrigine = Object.entries(itemResults?.facets?.['pays_d_origine']?.values ?? {});
  const paysOrigineCount = itemResults?.facets?.['pays_d_origine'].count;
  const labels = Object.entries(itemResults?.facets?.['labels']?.values ?? {});
  const labelsCount = itemResults?.facets?.['labels'].count;
  const natureDuPrixFacets = itemResults?.facets?.['nature_du_prix'];
  const promoCount = natureDuPrixFacets?.values['promo'] ?? 0;
  const produitMarcheCount = natureDuPrixFacets?.values['marche'] ?? 0;
  const temperatureLivraisonFacets = itemResults?.facets?.['temperature_livraison']?.values ?? {};
  const fraisCount = temperatureLivraisonFacets['positif'] ?? 0;
  const surgeleCount = temperatureLivraisonFacets['negatif'] ?? 0;
  
  const {params: filters, change: changeFilter, changeMultiple} = filtersHook;
  const {t} = useTranslation();
  const location = useLocation();
  const capture = useCapture();

  const searchParams = new URLSearchParams(location.search);
  const fromPromo = searchParams.get('from') === 'promo';
  const hasPromo = promoCount > 0;
  const hasProduitMarche = produitMarcheCount > 0;

  const selectedLabels = filters.labels ?? [];

  // Vérifier si des filtres sont actifs
  const hasActiveFilters = useMemo(() => {
    return !!(
      (filters.nature_du_prix ??
      (filters.labels && filters.labels.length > 0)) ??
      filters.temperature_livraison
    );
  }, [filters.nature_du_prix, filters.labels, filters.temperature_livraison]);

  const handleRemoveAllFilters = () => {
    if (!hasActiveFilters) return;

    // Réinitialiser les filtres globaux
    changeMultiple({
      'nature_du_prix': undefined,
      'labels': [],
      'pays_d_origine': undefined,
      'temperature_livraison': undefined
    });

    // Pour la modale de remplacement
    changeFilter('nature_du_prix', undefined);
    changeFilter('labels', undefined);
    changeFilter('temperature_livraison', undefined);
  };

  // On filtre avec le pays d'origine
  // const handlePaysChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedValue = event.target.value;
  //   changeFilter('pays_d_origine', selectedValue === 'pays_d_origine' ? undefined : selectedValue);
  // };

  // On filtre avec le label
  const handleLabelChange = (label: string, checked: boolean) => {
    const newLabels = checked
      ? [...selectedLabels, label]
      : selectedLabels.filter(l => l !== label);
    changeFilter('labels', newLabels.length > 0 ? newLabels : undefined);
    capture({
      name: 'catalogue:filtre',
      props: {champ: label, valeur: checked ? 'true' : 'false'}
    });
  };

  // On gere le classement des produits par ordre alphabetique
  const handleSortChange = () => {
    const newSortBy = filters.sortBy === 'alphabetique' ? undefined : 'alphabetique';
    changeFilter('sortBy', newSortBy);
    capture({
      name: 'catalogue:tri',
      props: {par: newSortBy ?? 'pertinence'}
    });
  };

  // on filtre pour les produits surgelé/non surgelé
  const handleNonSurgeleSurgeleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const newValue = selectedValue === 'all' ? undefined : selectedValue as TemperatureLivraison;
    changeFilter('temperature_livraison', newValue);
    capture({
      name: 'catalogue:filtre',
      props: {champ: 'temperature_livraison', valeur: newValue ?? 'all'}
    });
  };

  return (
    <HStack pt={{base: '0', md:direction === 'column-reverse' ? '0' : '10px'}}
      pb={{base: '10px', md: '0'}}
      pr={{base: '0', md: '20px'}}
      pl={{base:'0', md:'5px'}}
      width="100%"
      justifyContent={{base: 'center', md: 'space-between'}}
      spacing={0}
      {...props}>
      {/* Version desktop */}
      <Flex direction="column"
        w='100%'
        display={{base:'none', md:'flex'}}>
        <Flex justify='space-between'
          w='100%'
          direction={direction ?? 'row'} >
          <HStack spacing={0}
            ml='15px'>
            {/* Selection Promotion */}
            {hasPromo &&
              <Box height='38px'
                p='7px 11px 7px 11px'
                borderRadius='8px'
                fontWeight="400"
                color="blue.main"
                border="1px"
                borderColor={filters.nature_du_prix === 'promo' ? 'gray.dark' : 'gray.light'}
                bg='white'
                marginRight='10px'>
                <Checkbox
                  borderColor='gray.light'
                  size='sm'
                  isChecked={filters.nature_du_prix === 'promo'}
                  onChange={(event) => {
                    if (event.target.checked) {
                      changeFilter('nature_du_prix', 'promo');
                    } else {
                      changeFilter('nature_du_prix', undefined);
                    }
                  }}
                  isDisabled={fromPromo}
                  sx={{
                    '& .chakra-checkbox__control': {
                      borderColor: 'gray.light',
                      _checked: {
                        bg: 'red.200',
                        borderColor: 'red.200',
                      },
                    },
                  }}
                >
                  <HStack>
                    <Text color="red.200">{t('catalogue.filtre.promotions')}</Text>
                  </HStack>
                </Checkbox>
              </Box>
            }

            {/* Selection Produit Marché */}
            <HaliRenderOne nav={clientNav}
              skeleton={() => null}
              render={({data: clientData}) => {
              // Afficher le filtre produit marché uniquement si l'utilisateur a le droit de voir les produits marché
                if (clientData.affichage_produit_marche === 'active' && hasProduitMarche) {
                  return (
                    <Box height='38px'
                      p='7px 11px 7px 11px'
                      borderRadius='8px'
                      fontWeight="400"
                      color="blue.main"
                      border="1px"
                      borderColor={filters.nature_du_prix === 'marche' ? 'gray.dark' : 'gray.light'}
                      bg='white'
                      marginRight='10px'>
                      <Checkbox
                        borderColor='gray.light'
                        size='sm'
                        isChecked={filters.nature_du_prix === 'marche'}
                        onChange={(event) => {
                          if (event.target.checked) {
                            changeFilter('nature_du_prix', 'marche');
                          } else {
                            changeFilter('nature_du_prix', undefined);
                          }
                        }}
                        sx={{
                          '& .chakra-checkbox__control': {
                            borderColor: 'gray.light',
                            _checked: {
                              bg: 'blue.main',
                              borderColor: 'blue.main',
                            },
                          },
                        }}
                      >
                        <HStack>
                          <Text color="blue.main">{t('catalogue.filtre.produit_marche')}</Text>
                        </HStack>
                      </Checkbox>
                    </Box>
                  );
                }
                return null;
              }}/>

            {/* Selection des labels */}
            <Popover>
              <PopoverTrigger>
                <Button
                  color='black.text'
                  bg='white'
                  border='1px'
                  borderColor={selectedLabels.length > 0 ? 'gray.dark' : 'gray.light'}
                  borderRadius='8px'
                  fontSize='sm'
                  fontWeight='400'
                  height='38px'
                  marginRight='10px'
                  rightIcon={<ChevronDownIcon />}
                  isDisabled={!labelsCount || labelsCount <= 0}
                >
                  {t('catalogue.filtre.labels')}
                </Button>
              </PopoverTrigger>
              <PopoverContent marginLeft='10px'
                width="300px">
                <PopoverBody maxH="300px"
                  overflowY="auto"
                  backgroundColor='white'
                  p={4}>
                  <Flex direction="column"
                    gap={2}>
                    {labels.map(([label, count]) => (
                      count ? (
                        <Checkbox
                          key={label}
                          isChecked={selectedLabels.includes(label)}
                          onChange={(e) => {handleLabelChange(label, e.target.checked);}}
                          size='sm'
                          display="flex"
                          alignItems="center"
                        >
                          <HStack justify="space-between"
                            flex="1">
                            <Text fontSize="sm">{label}</Text>
                          </HStack>
                        </Checkbox>
                      ) : null
                    ))}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            {/* <Select
            bg='white'
            borderColor='gray.light'
            borderTopLeftRadius='none'
            borderBottomLeftRadius='none'
            icon={<ChevronDownIcon color='blue.main' />}
            iconSize='xs'
            w='inherit'
            maxW={hasPromo ? '130px' : 'none'}
            fontSize='sm'
            fontWeight='400'
            height='38px'
            value={filters.pays_d_origine ? filters.pays_d_origine : 'pays_d_origine'}
            onChange={handlePaysChange}
            isDisabled={!paysOrigineCount || paysOrigineCount <= 0}
          >
            <option value="pays_d_origine">{t('catalogue.filtre.origine')} ({paysOrigineCount})</option>
            {paysOrigine.map(([pays, count]) => (
              <option key={pays} value={pays}>{t(`countries.${pays}`)} ({count})</option>
            ))}
          </Select> */}

            {/* Selection Non surgelé / Surgelé */}
            <Popover>
              <PopoverTrigger>
                <Button
                  color='black.text'
                  bg='white'
                  border='1px'
                  borderColor={filters.temperature_livraison === 'negatif' || filters.temperature_livraison === 'positif'  ? 'gray.dark' : 'gray.light'}
                  borderRadius='8px'
                  fontSize='sm'
                  fontWeight='400'
                  height='38px'
                  marginRight='10px'
                  rightIcon={<ChevronDownIcon />}
                  isDisabled={(!fraisCount && !surgeleCount) || (fraisCount <= 0 && surgeleCount <= 0)}
                >
                  {t('catalogue.filtre.non_surgele_surgele')}
                </Button>
              </PopoverTrigger>
              <PopoverContent marginLeft='10px'
                width="300px">
                <PopoverBody maxH="300px"
                  overflowY="auto"
                  backgroundColor='white'
                  p={4}>
                  <Flex direction="column"
                    gap={2}>
                    {fraisCount > 0 && (
                      <Checkbox
                        isChecked={filters.temperature_livraison === 'positif'}
                        onChange={(e) => {handleNonSurgeleSurgeleChange({
                          target: {value: e.target.checked ? 'positif' : 'all'}
                        } as React.ChangeEvent<HTMLSelectElement>);}}
                        mb={2}
                        size='sm'
                      >
                        {t('catalogue.filtre.non_surgele')}
                      </Checkbox>
                    )}
                    {surgeleCount > 0 && (
                      <Checkbox
                        isChecked={filters.temperature_livraison === 'negatif'}
                        onChange={(e) => {handleNonSurgeleSurgeleChange({
                          target: {value: e.target.checked ? 'negatif' : 'all'}
                        } as React.ChangeEvent<HTMLSelectElement>);}}
                        size='sm'
                      >
                        {t('catalogue.filtre.surgele')}
                      </Checkbox>
                    )}
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </HStack>
          <Box>
            <Select bg='white'
              height='38px'
              maxW='180px'
              pl='15px'
              mb={direction && '10px'}
              border='1px'
              borderRadius='8px'
              fontWeight="400"
              color="blue.main"
              borderColor='blue.main'
              fontSize='sm'
              icon={<ArrowUpDownIcon mr='5px'/>}
              iconSize='sm'
              w='auto'
              value={filters.sortBy ?? 'pertinence'}
              onChange={handleSortChange}>
              <option value="pertinence">{t('catalogue.filtre.tri')} {t('catalogue.filtre.pertinence')}</option>
              <option value="alphabetique">{t('catalogue.filtre.tri')} {t('catalogue.filtre.a-z')}</option>
            </Select>
          </Box>
        </Flex>

        {/* Affichage des tags pour les filtres sélectionnés */}
        {hasActiveFilters && (
          <Flex w='100%'
            mt={2}>
            <HStack spacing={2}
              alignItems="center">
              <Wrap spacing={2}
                ml='15px'>
                {/* Tag pour les promotions */}
                {filters.nature_du_prix === 'promo' && (
                  <WrapItem>
                    <Tag size="md"
                      bg="gray.100"
                      borderRadius="8px">
                      <TagLabel>{t('catalogue.filtre.promotions')}</TagLabel>
                      <TagCloseButton onClick={() => {changeFilter('nature_du_prix', undefined);}} />
                    </Tag>
                  </WrapItem>
                )}

                {/* Tag pour Produit marché */}
                {filters.nature_du_prix === 'marche' && (
                  <WrapItem>
                    <Tag size="md"
                      bg="gray.100"
                      borderRadius="8px">
                      <TagLabel>{t('catalogue.filtre.produit_marche')}</TagLabel>
                      <TagCloseButton onClick={() => {changeFilter('nature_du_prix', undefined);}} />
                    </Tag>
                  </WrapItem>
                )}

                {/* Tag pour les labels */}
                {selectedLabels.map(label => (
                  <WrapItem key={label}>
                    <Tag size="md"
                      bg="gray.100"
                      borderRadius="8px">
                      <TagLabel>{label}</TagLabel>
                      <TagCloseButton onClick={() => {handleLabelChange(label, false);}} />
                    </Tag>
                  </WrapItem>
                ))}

                {/* Tag pour les produits surgelés/non surgelés */}
                {filters.temperature_livraison && (
                  <WrapItem>
                    <Tag size="md"
                      bg="gray.100"
                      borderRadius="8px">
                      <TagLabel>
                        {filters.temperature_livraison === 'positif'
                          ? t('catalogue.filtre.non_surgele')
                          : t('catalogue.filtre.surgele')}
                      </TagLabel>
                      <TagCloseButton onClick={() => {changeFilter('temperature_livraison', undefined);}} />
                    </Tag>
                  </WrapItem>
                )}

              </Wrap>

              {/* Bouton pour effacer tous les filtres */}
              <Box>
                <Tooltip label={t('catalogue.filtre.effacer')}
                  hasArrow>
                  <Button
                    variant="unstyled"
                    px={2}
                    bg="transparent"
                    onClick={handleRemoveAllFilters}
                    display="flex"
                    alignItems="center"
                  >
                    <Text fontSize='small'
                      color="gray.500"
                      textDecoration='underline'
                      _hover={{textDecoration:'none'}}
                      transition="all 0.2s">{t('catalogue.filtre.tout_effacer')}</Text>
                  </Button>
                </Tooltip>
              </Box>

            </HStack>
          </Flex>
        )}
      </Flex>

      {/* Version mobile */}
      <>
        <HStack bg="white"
          width='100%'
          spacing={0}
          display={{base:'flex', md:'none'}}>
          {hasPromo && <Box height='38px'
            fontWeight="400"
            color="blue.main"
            border="1px"
            borderColor='gray.light'
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center">
            <HStack spacing={2}>
              <Checkbox
                borderColor='gray.light'
                size='sm'
                isChecked={filters.nature_du_prix === 'promo'}
                textColor='red.200'
                onChange={(event) => {
                  if (event.target.checked) {
                    changeFilter('nature_du_prix', 'promo');
                  } else {
                    changeFilter('nature_du_prix', undefined);
                  }
                }}
                isDisabled={fromPromo}
                sx={{
                  '& .chakra-checkbox__control': {
                    borderColor: 'gray.light',
                    _checked: {
                      bg: 'red.200',
                      borderColor: 'red.200',
                    },
                  },
                }}
              >
                {t('catalogue.filtre.promo')} ({promoCount})
              </Checkbox>
            </HStack>
          </Box>
          }
          <Box height='38px'
            fontSize='sm'
            fontWeight="400"
            color="grey.light"
            border="1px"
            borderColor='gray.light'
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center" 
            onClick={() => {setIsFilterModalOpen(true);}}
            cursor="pointer"
          >
            <HStack spacing={2}>
              <Text>{t('catalogue.filtre.filtrer')}</Text>
              <FilterIcon />
              {hasActiveFilters && <Circle size="15px"
                bg="blue.main"/>}
            </HStack>
          </Box>

          <HaliRenderOne nav={clientNav}
            render={({data: clientData}) => {
              const showProduitMarche = hasProduitMarche && clientData.affichage_produit_marche === 'active';
              return (
                <CatalogFilterModale
                  modaleRemplacement={modaleRemplacement}
                  labels={labels}
                  labelsCount={labelsCount}
                  paysOrigine={paysOrigine}
                  promoCount={promoCount}
                  paysOrigineCount={paysOrigineCount}
                  hasPromo={hasPromo}
                  itemResults={itemResults}
                  filtersHook={filtersHook}
                  fraisCount={fraisCount}
                  surgeleCount={surgeleCount}
                  hasProduitMarche={hasProduitMarche}
                  produitMarcheCount={produitMarcheCount}
                  showProduitMarche={showProduitMarche}
                  isOpen={isFilterModalOpen}
                  onClose={() => {setIsFilterModalOpen(false);}}
                />
              );
            }}/>

          <HStack height='38px'
            spacing={2}
            flex={1}
            border="1px"
            borderColor='gray.light'
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor='pointer'
            onClick={handleSortChange}>
            <Text fontSize='sm'
              fontWeight="400">
              {t('catalogue.filtre.trier')}
            </Text>
            <ArrowUpDownIcon />
          </HStack>
        </HStack>
      </>
    </HStack>
  );
};
