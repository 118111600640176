import {useMe} from '@/haligator-factory';
import {useCapture} from '@/posthog/PosthogSetup';
import {Box, HStack, Heading, Stack, Switch, Text, VStack} from '@chakra-ui/react';
import {useHalState} from '@synako/haligator';
import {PreferencesAlertes} from 'api-types/ffconnect';
import {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

export const SettingsView: React.FC = () => {
  const {t} = useTranslation();
  const capture = useCapture();

  const contact = useHalState(useMe().follow('contact'));

  //mettre les switchs dans un tableau et les set à false au début
  const [allChecked, setAllChecked] = useState(false);
  const [switches, setSwitches] = useState<Record<string, boolean>>({
    livraison_email: false,
    paniers_email: false,
    livraison_notif: false,
    paniers_notif: false,
  });

  //switch global pour tout activer
  const handleAllChecked = () => {
    const newCheckedState = !allChecked; //inverse l'état actuel pour permettre de tout désactiver
    setAllChecked(newCheckedState);
    setSwitches({
      livraison_email: newCheckedState,
      paniers_email: newCheckedState,
      livraison_notif: newCheckedState,
      paniers_notif: newCheckedState,
    });

    updatePreferences({
      email: {
        prochaine_livraison: newCheckedState ? 'activee' : 'desactivee',
        paniers_a_valider: newCheckedState ? 'activee' : 'desactivee'
      },
      notification_mobile: {
        prochaine_livraison: newCheckedState ? 'activee' : 'desactivee',
        paniers_a_valider: newCheckedState ? 'activee' : 'desactivee'

      }
    });
  };

  const handleIndividualSwitch = (name: string | number) => {
    const newSwitches = {...switches, [name]: !switches[name]};
    setSwitches(newSwitches);
    // Si tous les switchs individuels sont activés, active "Tout activer"
    const allActivated = Object.values(newSwitches).every(v => v);
    setAllChecked(allActivated);

    updatePreferences({
      email: {
        prochaine_livraison: newSwitches['livraison_email'] ? 'activee' : 'desactivee',
        paniers_a_valider: newSwitches['paniers_email'] ? 'activee' : 'desactivee'
      },
      notification_mobile: {
        prochaine_livraison: newSwitches['livraison_notif'] ? 'activee' : 'desactivee',
        paniers_a_valider: newSwitches['paniers_notif'] ? 'activee' : 'desactivee'

      }
    });
  };

  const updatePreferences = (alertes: PreferencesAlertes) => {
    capture({
      name: 'client:changer_preferences',
      props: {alertes}
    });
    contact.state?.submitCommand('c:changer-preferences', {preferences: {alertes}});
  };

  useEffect(() => {
    if (contact.state) {
      const alertes = contact.state.data.preferences.alertes;
      setSwitches({
        livraison_email: alertes.email.prochaine_livraison === 'activee',
        paniers_email: alertes.email.paniers_a_valider === 'activee',
        livraison_notif: alertes.notification_mobile.prochaine_livraison === 'activee',
        paniers_notif: alertes.notification_mobile.paniers_a_valider === 'activee',
      });
      setAllChecked(
        alertes.email.prochaine_livraison === 'activee'
        && alertes.email.paniers_a_valider === 'activee'
        && alertes.notification_mobile.prochaine_livraison === 'activee'
        && alertes.notification_mobile.paniers_a_valider === 'activee',
      );
    }
  }, [contact.state]);

  if (!contact.state) {
    return;
  }
  return (
    <Box display="flex"
      bg="gray.bg"
      overflowX="hidden"
      width="100%"> {/* globale */}
      <Box
        p={{base: 4, md: 5}}
        mr={{base: '0px', md: '0px', xl:'40px', '2xl':'65px'}}
        ml={{base: '0px', md: '0px', xl:'40px', '2xl':'65px'}}
        width="100%"
        maxWidth="1000px" // Définir une largeur maximale
        overflow="hidden" // Gérer le débordement
      >
        <Heading size={{base:'md', md:'lg'}}
          ml={{base:'30px', md:'0'}}
          mb={6}
          mt="10px"
          color="black.text">{t('menu.preferences')}</Heading>
        <Box p={{base: 5, md: 8}}
          borderWidth="1px"
          borderRadius="lg"
          bg="white"
          height="auto"
          width="100%">
          <Stack direction={{base: 'column', lg: 'row'}}
            justifyContent="flex-start"
            mb={10}
            spacing={5}>
            <Heading fontSize={{base:'md', md:'lg'}}
              fontWeight="700">{t('preferences.alertes')}</Heading>
            <HStack alignItems="center" >
              <Switch
                colorScheme='green'
                id='tout-activer'
                isChecked={allChecked}
                onChange={handleAllChecked} />
              <Text>{t('preferences.activer')}</Text>
            </HStack>
          </Stack>
          <HStack align="start"
            spacing={10}
            width="100%"
            pr={0}
            display={{base: 'none', lg: 'flex'}}>
            <VStack spacing={5}
              alignItems="flex-start">
              <Text><br /></Text>
              <Text fontWeight="600">{t('preferences.livraison')}</Text>
              <Text fontWeight="600">{t('preferences.paniers')}</Text>
            </VStack>
            <VStack spacing={6}>
              <Text>{t('preferences.email')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['livraison_email']}
                onChange={() => {handleIndividualSwitch('livraison_email');}}
              />
              <Switch
                colorScheme='green'
                isChecked={switches['paniers_email']}
                onChange={() => {handleIndividualSwitch('paniers_email');}}
              />
            </VStack>
            <VStack spacing={6}>
              <Text>{t('preferences.mobile')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['livraison_notif']}
                onChange={() => {handleIndividualSwitch('livraison_notif');}}
              />
              <Switch
                colorScheme='green'
                isChecked={switches['paniers_notif']}
                onChange={() => {handleIndividualSwitch('paniers_notif');}}
              />
            </VStack>
          </HStack>
          <VStack display={{base: 'flex', lg: 'none'}}>
            <Text w="100%"
              fontWeight="600"
              textAlign="left"><Trans t={t}>{t('preferences.livraison_mobile')}</Trans></Text>
            <HStack w="90%"
              justify="space-between">
              <Text>{t('preferences.email')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['livraison_email']}
                onChange={() => {handleIndividualSwitch('livraison_email');}}
              />
            </HStack>
            <HStack w="90%"
              justify="space-between"
              mb="2em">
              <Text>{t('preferences.mobile')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['paniers_email']}
                onChange={() => {handleIndividualSwitch('paniers_email');}}
              />
            </HStack>
            <Text w="100%"
              fontWeight="600"
              textAlign="left">{t('preferences.paniers')}</Text>
            <HStack w="90%"
              justify="space-between">
              <Text>{t('preferences.email')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['livraison_notif']}
                onChange={() => {handleIndividualSwitch('livraison_notif');}}
              />
            </HStack>
            <HStack w="90%"
              justify="space-between"
              mb="2em">
              <Text>{t('preferences.mobile')}</Text>
              <Switch
                colorScheme='green'
                isChecked={switches['paniers_notif']}
                onChange={() => {handleIndividualSwitch('paniers_notif');}}
              />
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsView;
